export const statusHandler = async (codeUri, method, data) => {
  console.log("in status handler")
  const requestObj = {
    method, 
    headers: {
      'Content-Type': 'application/json'
    }
  }
  console.log("nothing makes sense")

  if (data) requestObj.data = JSON.stringify(data)
  try {
    console.log("in try")
    const response = await fetch(codeUri, requestObj);
    console.log("fetch")
    // const responseBody = await response.json();
    // todo fix
    if (response.status !== 200) {
      throw new Error("bad");
    }
    return response.json();

 } catch (err) {
    throw new Error(err);
 }
}