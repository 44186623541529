import { TextField, Button, Snackbar, Alert } from "@mui/material";
import './ContactView.scss';
import '../../App.scss';
import { useMutation } from "react-query";
import { useState } from "react";
import { statusHandler } from "../../api/statusHandler";

const classes = {
  textArea: {
    width: 'calc(100% - 8rem)', 
    fontSize: '1rem', 
    margin: '3rem'
  }, 
  filledGradientButton: {
    backgroundImage: 'linear-gradient(to bottom right, #2E0099, #FFC2E8)',
    borderRadius: '1.5rem',
    color: 'white',
    fontFamily: 'Serif Display',
    padding: '.5rem 2rem'
  } 
}

const emptyEmailObj = {
  message: '', 
  subject: '', 
  reply_addy: ''
}

const ContactView = () => {
// todo typescript you idiot
  const [emailObj, setEmailObj] = useState(emptyEmailObj);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // need a fetch handler that throws errors for bad status codes to get the error handling to work!!
  // maybe a general error handler as well! 
  const sendEmail = useMutation((emailObj) => {
    statusHandler('/api/email', 'POST', emailObj)
  }, {
    onSettled: () => {
      setOpenSnackbar(true);
      setEmailObj(emptyEmailObj);
    }
  });

  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    sendEmail.mutate(emailObj);
  }

  return (
    <div className="Contact__container">
      <form onSubmit={handleFormSubmit}>
        <h1 className="Contact__title">Contact Me</h1>
        <div className="Contact__formLayout">
          <TextField
            aria-label="reply address"
            label="Reply Address"
            placeholder="Must be a valid email address"
            type="email"
            fullWidth
            required
            value={emailObj.reply_addy}
            onChange={(event) => setEmailObj((prevValue) => ({...prevValue, reply_addy: event.target.value}))}
          />
          <TextField
            aria-label="email subject"
            label="Subject"
            fullWidth
            required
            value={emailObj.subject}
            onChange={(event) => setEmailObj((prevValue) => ({...prevValue, subject: event.target.value}))}          
          />
        </div>
        <TextField
          aria-label="email message"
          multiline
          required
          label="Message"
          value={emailObj.message}
          onChange={(event) => setEmailObj((prevValue) => ({...prevValue, message: event.target.value}))}
          minRows={15}
          placeholder="Send me an email. I'd love to hear from you..."
          sx={classes.textArea}
        />
        <div className="Contact__buttonContainer">
          <Button type="submit" sx={classes.filledGradientButton}>send</Button>
        </div>
      </form>
      <Snackbar open={openSnackbar} onClose={() => setOpenSnackbar(false)}>
        {sendEmail.isSuccess ? 
          <Alert severity="success" color="secondary">{typeof sendEmail.data === 'string' ? sendEmail.data : 'Rebecca says thanks! She\'ll get back to you as soon as she can.'}</Alert>
        : 
          <Alert severity="error" color="primary">{sendEmail.error ? sendEmail.error : 'An unexpected error has occurred. In the meantime, you can reach Rebecca here.'}</Alert>
        }
      </Snackbar>
    </div>
  )
};

export default ContactView;