import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LandingView from './Components/LandingView/LandingView';
import ProjectsView from './Components/ProjectsView/ProjectsView';
import AboutView from './Components/AboutView/AboutView';
import { BrowserRouter } from 'react-router-dom';
import ContactView from './Components/ContactView/ContactView';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
    const queryClient = new QueryClient()
    return (
        <>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <LandingView/>
                        <AboutView/>
                        <ProjectsView/>
                        <ContactView/>
                    </BrowserRouter>
                </QueryClientProvider>
            </ThemeProvider>
        </>
    );
};


const theme = createTheme({
    typography: {
      fontFamily: '"Serif Display"',
      button: {
        textTransform: 'none'
      }
    },
    palette: {
        primary: {
            main: '#2E0099', // purple
            dark: '#A21645',
        }, 
        secondary: {
            light: '#D492D4',
            main: '#A861C0',
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Lato Light'
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Lato Light'
                }
            },
        },
    }
  });
  
export default App;
